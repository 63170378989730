(function (window) {
	/**
	 * BatchGeoSharedMaps is a "glue" component that glues together many other
	 * components to create the My Maps page.
	 *
	 * @constructor
	 */
	var BatchGeoSharedMaps = function () {
		this._loadMaps();
	};

	/**
	 * This method actually requests all the admin maps and then injects them into
	 * the DOM. It also handles the loader.
	 *
	 * @private
	 */
	BatchGeoSharedMaps.prototype._loadMaps = function () {
		var self = this;

		// Place the loader component
		new BatchGeoAdminMapTableLoader({
			container: '.admin-map-table-loader-wrapper'
		});

		// Trigger the loader to display now right before we fetch the data
		BatchGeoStore.dispatch({
			type: 'ADMIN_SHARED_MAP_TABLE_LOADER_VISIBILITY',
			visibility: true
		});

		// Get all, non-archived, admin maps
		BatchGeoApi().shared().maps(function (xhr, status) {
			var data = status == 'error' ? [] : xhr.responseJSON;
			// Once the request is done we can hide the loader
			BatchGeoStore.dispatch({
				type: 'ADMIN_SHARED_MAP_TABLE_LOADER_VISIBILITY',
				visibility: false
			});

			// If the data that was returned was empty show the no maps message
			if (data && data.length == 0) {
				$('.admin-map-table-wrap-no-maps').show();
			}

			self._initSharedMapTable(data);
		});
	};

	/**
	 * Creates the actual table from the given data. It calls
	 * BatchGeoAdminMapTable which handles Clusterize, templating, and everything.
	 *
	 * @param {array} mapTableData The data to build the table with. It expects
	 * the data in the format from the BatchGeo Node API
	 *
	 * @private
	 */
	BatchGeoSharedMaps.prototype._initSharedMapTable = function (mapTableData) {
		new window.BatchGeoAdminMapTable({
			container: $('.admin .container')[0],
			data: mapTableData,
			template: function (row, rowIndex) {
				return '' +
				  '<tr class="' + (rowIndex % 2 ? 'odd' : 'even') + '">' +
				    '<td class="admin-map-table-title-wrap">' +
				      '<div class="admin-map-table-title-wrap-inner">' +
				        '<span class="admin-map-table-title"><a href="/map/' + row.alias + '">' + row.title + '</a>' +
				          (row.archived ? '<span class="icon toolbar-icon-archive"></span>' : '') +
				        '</span>' +
				      '</div>' +
				    '</td>' +
				    '<td class="email">' + row.email + '</td>' +
				    '<td class="action export-action" nowrap>' +
				      '<a class="button buttonsmall white" href="/map/pdf/' + row.alias + '" rel="nofollow">PDF</a>' +
				      '<a class="button buttonsmall white" href="/map/png/' + row.alias + '" rel="nofollow">Image</a>' +
				      '<a class="button buttonsmall white" href="/map/kml/' + row.alias + '" rel="nofollow">KML</a>' +
				    '</td>' +
				    '<td class="center updated">' + row.date_updated + '</td>' +
				  '</tr>';
			}
		});

		// BatchGeoAdminMapTable will generate the search box. If there's ever no
		// rows in the table it's probably because of a search term filtering it
		// down and then the user did some action like archiving all in that filter.
		// We know that the user has maps at this point so the only other reason, so
		// far, that would cause no maps is a search term. So if no maps show this.
		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTable.displayRows;
			},
			function (newRows, oldRows) {
				return newRows.length !== oldRows.length
			},
			function (newRows, oldRows) {
				$('.admin-map-table-no-results').toggle(newRows.length < 1);
			}
		)
	};

	window.BatchGeoSharedMaps = BatchGeoSharedMaps;
})(window);
