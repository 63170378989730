(function (window) {
	const waZipCodes = require('../../washington-state-zip-codes.json');
	/**
	 * A little component to handle the actual sign up form. It creates a
	 * BatchGeoForm instance and also attaches other misc notices and
	 * validations to the form such as Washington State Tax notice.
	 * 
	 * @param {object} options 
	 * @param {*} options.formSelector The form element. This accepts anything
	 * jQuery accepts. Under the hood it calls $(settings.form) so you can pass
	 * a string, another jQuery object, DOM node, etc.
	 */
	var BatchGeoSignUpPageForm = function (options) {
		this.settings = $.extend(true, {
			formSelector: null
		}, options)

		// Cache the selectors we need for the form now
		this._cacheElements();
		// Enable the form - Builds BT 3DS Dropin
		this._createBatchGeoForm();
		// Inject cached form values into form fields
		this._hydrateFormValues();

		this._setupFormListeners();
	}

	/**
	 * A private function that hydrates the form's state input using the user's IP
	 * 
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._hydrateFormPostalCodeValueWithIP = function () {
		$.ajax({
			async: true,
			type: "GET",
			url: '/api/maxmind/',
			success: function (results) {
				if (!results || !results.zipcode) {
					// no results or data to handle
					return;
				} else if (results.zipcode) {
					// has zip
					$('#postal_code').val(results.zipcode);
				}
			},
			dataType: "json",
			cache: true
		});
	}

	/**
	 * A private function that hydrates the form's email input using the 'LAST_EMAIL' cookie
	 * 
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._hydrateFormEmailValueWithCookie = function () {
		const cookieValue = window.BatchGeo.getCookie('LAST_EMAIL');
		if(cookieValue) {
			var context = $(this.settings.formSelector);
			const input = $('input[name="email_address"]', context);
			input.val(cookieValue);
		}
	}

	/**
	 * A private function that hydrates the forms using session storage
	 * 
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._hydrateFormValues = function () {
		const formPersistence = window.sessionStorage.getItem("signup");
		if(!formPersistence) {
			this._hydrateFormEmailValueWithCookie();
			this._hydrateFormPostalCodeValueWithIP();
			return; // if no form persistence exists, do nothing
		}
		const parsedFormData = JSON.parse(formPersistence);
		for(const [key, value] of Object.entries(parsedFormData)){
			var context = $(this.settings.formSelector)
			if (key == "plan") {
				// radio input
				if (window.location.search.search("term") === -1) {
					// use cache if term is not explicitly set in url
					const input = $(`input[name=${key}][value=${value}]`, context)[0];
					if (input) input.checked = true;
				}
			} else {
				//text input
				const input = $(`input[name=${key}]`, context);
				input.val(value);	
			}
		}
	}

	/**
	 * A private function that simply caches the elements for elsewhere and also
	 * gives a single place to update selectors.
	 * 
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._cacheElements = function () {
		var context = $(this.settings.formSelector);
		this._elements = {
			$buttons: $('.buttons', context),
			$submitButton: $('#grn_btn', context),
			$securityCodeHelper: $('.security-code-helper-text', context),
			$waStateTaxNotice: $('#sales-tax', context)
		}
	}
	
	/**
	 * Updates the CVV code helper text below the input. If it's an AmEx card it
	 * will tell you there's a 4 digit number on the front and if it's Visa a
	 * 3 digit code on the back.
	 */
	// ⚠️ With implementation of 3DS Batchgeo is not handling
	// raw credit card data or calling this function. 
	BatchGeoSignUpPageForm.prototype.creditCardNumberInputHandler = function () {
		var $securityCodeElement = this._elements.$securityCodeHelper;
		var type = BatchGeo.creditCardTypeFromNumber(this.form.getField('cc_number').value);
		
		switch(type) {
			case 'AmEx': $securityCodeElement.text('4 Digit Security Code on Front of Card'); break;
			case 'Visa': $securityCodeElement.text('3 Digit Security Code on Back of Card');  break;
		}
	}

	/**
	 * Toggles the notice about Washington sales tax if the value given is WA.
	 */
	BatchGeoSignUpPageForm.prototype.postalCodeInputHandler = function () {
		console.log("AT POSTAL CODE INPUT HANDLER");
		const isWaZipCode = waZipCodes.indexOf(parseInt(this.form.getField('postal_code').value)) !== -1;
		$(this._elements.$waStateTaxNotice).toggle(isWaZipCode);
		console.log(document.getElementById('state'));
		if (isWaZipCode) document.getElementById('state').value = "WA";
	}

	/**
	 * Pings an endpoint to delete the braintree customer and surfaces an block alert modal message
	 * 
	 * @param {string} email users email
	 * @param {string} error caught error
	 * @param {string} logMessage message we want to log
	 */
	BatchGeoSignUpPageForm.prototype.handleVerifyCardWith3DSecureError = function (email, error, logMessage = '') {
		console.error(logMessage, error.message);
		// Ping endpoint to delete customer and log errors/messages
		$.ajax({
			method: 'POST',
			url: '/api/braintree-utils/delete-bt-customer/',
			data: { email, logMessage, error: error.message },
			success: function(data){ 
				console.log('Success deleting customer, or no customer to delete', data);
				BatchGeo.blockAlert('An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.', () => window.location.reload(true));
			},	
			error: function(err){
				console.error("Failed to delete customer", err);
				BatchGeo.blockAlert('An error occured. Please contact Batchgeo support.', () => window.location.reload(true))
			}
		})
	}

	/**
	 * This method initiates 3DS verification for 3DS supported card types returned from api
	 * @param  threeDSecureInfo = {nonce, email, customerId, clientToken}  
	 * @returns 
	 */
	BatchGeoSignUpPageForm.prototype.verifyCardWith3DSecure = function (threeDSecureInfo, email, password, firstName, lastName, company, postalCode) {		
		var self = this;
		let { nonce, customerId, clientToken, planId, bin } = threeDSecureInfo; 
		
		if (!nonce || !customerId || !clientToken || !planId || !bin) {
			console.error("Cannot verify card without threeDSecureInfo"); 
		}
		
		const threeDSecureParameters = {
			amount: '0', 
			// To test error handling, comment out nonce
			nonce,
			bin,
			onLookupComplete: function (data, next) {
				next();
			},
			email,
			billingAddress: {
				postalCode: postalCode
			}
		}

		braintree.client.create({
			// Use the generated client token to instantiate the Braintree client.
			authorization: clientToken
		})
		.then(function(clientInstance) {
				return braintree.threeDSecure.create({
						version: 2, // Will use 3DS2 whenever possible
						client: clientInstance
				});
		})
		.then(function(threeDSecureInstance) {
			threeDSecureInstance.verifyCard(threeDSecureParameters).then(function (response) {				
				$.ajax({
					method: 'POST',
					url: '/api/braintree-utils/3ds-signup/', // needs the ending / otherwise it will redirect to the GET route
					data: { nonce: response.nonce, customerId, planId, email, password, firstName, lastName, company},
					complete: function(xhr, status){ 
						var createBtSubResponse = xhr.responseJSON;
						self.ajaxResponseHandler(status, createBtSubResponse, customerId);
					},	
					error: function(err){
						self.handleVerifyCardWith3DSecureError(email, err, 'Error sending verified card nonce to server');
					}
				})
			})
			.catch(function(error) {
				self.handleVerifyCardWith3DSecureError(email, error, "Error verifying user card");
			});
		})
		.catch(function(error) {
			self.handleVerifyCardWith3DSecureError(email, error, "Error creating braintree client");
		});
	}

	/**
	 * The ajax response handler handles responses from AJAX calls. 
	 * Takes status and JSON as params. 
	 */

	BatchGeoSignUpPageForm.prototype.ajaxResponseHandler = function(status, json, trackingId) {
		var strings = window.BatchGeoStrings.getStringsForComponent('BatchGeoSignUp');
		// If we got a JSON response (we may not like a 500
		// error) and there's an error property we can expect a
		// message on it. Show that message to the user. We also
		// want to scroll to the error'd element.

		//user data
		let email = null;

		//stored user data
		let newUserData = JSON.parse(window.sessionStorage.getItem('signup'));

		//ensure email exists before assigning to email
		if(newUserData.email_address){
			email = newUserData.email_address
		}

		if (json && json.error) {
			const message = strings.get(json.error.message);
			BatchGeo.blockAlert(message, function() {
				window.location = '/signup';
			});
			return;
		}

		if (json && status === 'success') {
	

			if(trackingId){
				gtag("event", "purchase", {
					transaction_id: trackingId,
					affiliation: "BatchGeo",
					value: json.base_price,
					customerEmail: email,
					tax: 0,
					shipping: 0,
					currency: "USD",
					coupon: "",
					items: [
					// If someone purchases more than one item, 
					// you can add those items to the items array
						{
						item_id: "SKU_54321",
						item_name: "Subscription",
						affiliation: "BatchGeo",
						customerEmail: email,
						coupon: "",
						discount: 0,
						index: 0,
						item_brand: "BatchGeo",
						item_category: "Subscription",
						price: json.base_price,
						quantity: 1
					}]
				});
			}

			const message = strings.get(json.message);
			// DO NOT REMOVE THIS CONSOLE LOG
			// There is unusual .get() behavior when singing up  3d secure accounts
			// in which message is undefined when rendering the modal. 
			// This could be a lazy-loading issue. 
			// Logging the value before calling blockAlert forces the message to be available
			// before we need it in the modal. 
			console.log(message);
			
			// Redirect them on success back home after we tell
			// them congrats
			BatchGeo.blockAlert(message, function() {
				sessionStorage.setItem('showAddUserModal', true);
				window.location = '/';
			});
		}

		// If the response wasn't JSON or it didn't have an
		// error field (i.e. if we got `{}`) then show a generic
		// error to users since we don't know what it'd say.
		else {
			const message = strings.get("ERROR_TRY_AGAIN");
			BatchGeo.blockAlert(message, function() {
				window.location = '/signup';
			});
		}
	}
	

	/**
	 * The form submit handler handles submitting the AJAX form. It takes a
	 * BatchGeoForm instance as the only param and does the rest. It does NOT
	 * provide a way to manage the callback as this is all handled by this
	 * one handler.
	 *
	 * @param {BatchGeoForm} batchGeoFormInstance The BG Form instance to pull
	 * form data from. This will be used for the form data and captcha data.
	 *
	 * @returns {false} Will always return false (to prevent form submits)
	 */
	BatchGeoSignUpPageForm.prototype.formSubmitHandler = function (batchGeoFormInstance, captchaResponse) {
		var self = this;

		self._toggleLoader(true);

		const formData = batchGeoFormInstance.getFormAsData();

		// cache form data in session storage in case onboarding fails
		const {
			first_name,
			last_name,
			email_address,
			company_name,
			plan,
			postal_code,
			password
		} = formData;

		const formPersistence = {
			first_name,
			last_name,
			email_address,
			company_name, 
			plan, 
			postal_code
		};

		window.sessionStorage.setItem("signup", JSON.stringify(formPersistence));

		const threeDSecureParameters = {
			amount: '0',
			billingAddress: {
				postalCode: postal_code,
			}
		};

		return dropinInstance.requestPaymentMethod({ threeDSecure: threeDSecureParameters }).then(function(requestPaymentMethodPayload) {
			self._hideFauxBraintreePaymentOptionsButton();
			$.ajax({
				url: '/api/signup/',
				// Merge in the captchaResponse and paymentMethodNonce data for the API
				data: _.merge(
					formData,
					{
						g_recaptcha_response: captchaResponse,
						nonce: requestPaymentMethodPayload.nonce,
					}
				),
				dataType: 'json',
				cache: false,
				type: 'post',
				// The success response will return a script or redirect us
				complete: function (xhr, status) {
					var json = xhr.responseJSON;
					if (json) {
						// if json nonce then verify card
						if (json.nonce && json.customerId) {
							//self.verifyCard 
							//TESTING: To simulate the onboarding_incomplete flow, comment out the next line.
							self.verifyCardWith3DSecure(json, email_address, password, first_name, last_name, company_name, postal_code);
							return;
						} else {
							self.ajaxResponseHandler(status, json, null);
						}
					} else {
						self.ajaxResponseHandler(status, json, null);
					}
				},
				error: function (err) {
					self.handleVerifyCardWith3DSecureError(email_address, err, "Error in initial signup transaction.")
				}
			});
		}).catch(function(err) {
			console.log('Failed to requestPaymentMethod to BT: ', err);
			BatchGeo.blockAlert('An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.', () => window.location.reload(true))
		})
	}
	
	BatchGeoSignUpPageForm.prototype.test = function () {
		var self = this;
		// this.mockMe();
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve('foo');
			}, 300);
		}).then((response) => {
			this.mockMe(response);
		});
	}

	/**
	 * A small helper utility to show the loader and hide the submit if you pass
	 * it true and vice versa if you pass false. It's meant to show/hide the
	 * loader while the form is processing.
	 * 
	 * @param {boolean} toggledOn If true it will display the loader and hide
	 * submit button.
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._toggleLoader = function (toggledOn) {
		this._elements.$submitButton.toggle(!toggledOn);
		this.$loader.toggle(toggledOn);
	}

	/**
	 * Sets up and manages the BatchGeoForm instance. It attaches the form onto
	 * the prototype where you can access it at #form.
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._createBatchGeoForm = function () {
		var self = this;

		this._setup3dsDropIn();
		
		this.$loader = $('<div />', {
			addClass: 'loading-icon',
			toggle: false
		}).appendTo(this._elements.$buttons)

		this.form = new BatchGeoForm({
			form: this.settings.formSelector,
			enableCaptcha: true,
			handleSubmit: function (fields, captchaResponse) {
				self.formSubmitHandler(self.form, captchaResponse);
				// cancels actual submit action, we have to do this because we need formSubmitHandler to return the promise
				// TODO look into passing the event through from the jQuery submissions handler
				return false;
			},
			fields: {
				state: {},
				postal_code: {
					eventHandlers: {
						change: () => {
							self.postalCodeInputHandler();
						}
					}
				},
				password: {
					validator: function (value, fields) {
						return value.length >= 6 || 'Password must be at least 6 characters long'
					}
				},
				password_confirmation: {
					validator: function (value, fields) {
						return fields.password.$element.val() === value || 'Your passwords must match'
					}
				},
				email_address: {
					validateOnKeyPress: false,
					validator: function (value, fields, done) {
						if (value && value.trim().length > 0) {
							BatchGeoApi().testEmail(value, function (xhr) {
								done(xhr.responseJSON === 1 || 'It appears we have had trouble emailing ' + value + ' in the past, please try a different email address.');
							});
						} else {
							return done('This field is required!');
						}
					}
				},
				signup_given_name: 	{
					validator: function (value, fields) {
						if (fields.signup_given_name.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_surname: 	{
					validator: function (value, fields) {
						if (fields.signup_surname.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_street_address: 	{
					validator: function (value, fields) {
						if (fields.signup_street_address.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_locality: 	{
					validator: function (value, fields) {
						if (fields.signup_locality.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_region: 	{
					validator: function (value, fields) {
						if (fields.signup_region.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_postal_code: 	{
					validator: function (value, fields) {
						if (fields.signup_postal_code.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
				signup_country_code: 	{
					validator: function (value, fields) {
						if (fields.signup_country_code.$element.is(":visible")) {
							return value.length > 0  || 'This field is required!'
						}
					}
				},
			}
		});
	}
	/**
	 * Retrieves a BT clientToken from Server
	 * Uses server generated BT clientToken to instantiate BT Dropin
	 * Dropin instance saved to this.$dropinInstance
	 * @private
	 */
	BatchGeoSignUpPageForm.prototype._setup3dsDropIn = function(enablePaypal = false) {
		console.log('3DS Dropin: Instantiating...');
		$.ajax({
			type: 'GET',
			url: '/api/braintree-utils/signup-client-token/',
			async: true,
			success: function (data) {
				const clientToken = data.clientToken

				dropInConfig = {
					defaultFirst: true,
					authorization: clientToken,
					container: '#dropin-container',
				};

				if(enablePaypal){
					dropInConfig.paypal = {
						flow: 'vault'
					}
				}

				braintree.dropin.create(dropInConfig)
				.then(function (newDropinInstance) {
					this.dropinInstance = newDropinInstance;
				}).catch(function (error) {
					console.error('Failed to instantiate 3DS Drop-in. Double check your clientToken', error);
					BatchGeo.blockAlert('An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.', () => window.location.reload(true))
				});
			},
			error: (err) => {
				console.log('Failed to retrieve clientToken from server: ', err);
				BatchGeo.blockAlert('An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.', () => window.location.reload(true))
			}
		});
	}

	BatchGeoSignUpPageForm.prototype._handlePaymentOptionsClick = function () {
		try {
			window.dropinInstance.teardown(() => {
				this._setup3dsDropIn(true);
				$('.faux-braintree-payment-options-button').hide();
			})
		} catch (error) {
			console.error('Failed to tear down 3DS Drop-In', error);
			BatchGeo.blockAlert('An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.', () => window.location.reload(true))			
		}
	}

	BatchGeoSignUpPageForm.prototype._setupFormListeners = function () {
		if($('.faux-braintree-payment-options-button')){
			$('.faux-braintree-payment-options-button').on('click', this._handlePaymentOptionsClick.bind(this));
		}
	}

	BatchGeoSignUpPageForm.prototype._hideFauxBraintreePaymentOptionsButton = function () {
		if($('.faux-braintree-payment-options-button')){
			$('.faux-braintree-payment-options-button').hide();
		}
	}

	window.BatchGeoSignUpPageForm = BatchGeoSignUpPageForm;
})(window);
