(function (window) {
	/**
	 * BatchGeoMyMaps is a "glue" component that glues together many other
	 * components to create the My Maps page.
	 *
	 * @constructor
	 */
	var BatchGeoMyMaps = function () {
		this._loadMaps();
		this._initShowingDropdown();
		this._initBulkActionsSelectbox();
		this._initInlineNotification();
	};

	/**
	 * This method actually requests all the admin maps and then injects them into
	 * the DOM. It also handles the loader.
	 *
	 * @private
	 */
	BatchGeoMyMaps.prototype._loadMaps = function () {
		var self = this;
		var filter = BatchGeoStore.getState().AdminMapTable.filter;

		// Place the loader component
		new BatchGeoAdminMapTableLoader({
			container: '.admin-map-table-loader-wrapper'
		});

		// Trigger the loader to display now right before we fetch the data
		BatchGeoStore.dispatch({
			type: 'ADMIN_MAP_TABLE_LOADER_VISIBILITY',
			visibility: true
		});

		// Get all, non-archived, admin maps
		BatchGeoApi().admin()[filter](function (xhr, status) {
			var data = status == 'error' ? [] : xhr.responseJSON;

			// Once the request is done we can hide the loader
			BatchGeoStore.dispatch({
				type: 'ADMIN_MAP_TABLE_LOADER_VISIBILITY',
				visibility: false
			});

			// If the data that was returned was empty show the no maps message
			if (data && data.length == 0) {
				$('.admin-map-table-wrap-no-maps').show();
			}

			self._initAdminMapTable(data);
			self._initAdminMapTableCheckboxes();
		});
	};

	/**
	 * Adds the handlers to toggle between the unarchived and "all maps" list
	 * @private
	 */
	BatchGeoMyMaps.prototype._initShowingDropdown = function () {
		var $dropdown = $('.admin-map-showing-dropdown');
		var defaultFilter = BatchGeoStore.getState().AdminMapTable.filter;

		// Select the default filter so that the dropdown reflects the data
		// being displayed below
		$dropdown.find('option[value='+defaultFilter+']').prop('selected', true);

		$dropdown.on('change', function () {
			var mapsMethod = $(this).val();

			// While we fetch the new map list show the loader
			BatchGeoStore.dispatch({
				type: 'ADMIN_MAP_TABLE_LOADER_VISIBILITY',
				visibility: true
			});

			BatchGeoApi().admin()[mapsMethod](function (xhr, status) {
				var data = status == 'error' ? [] : xhr.responseJSON;

				BatchGeoStore.dispatch({
					type: 'ADMIN_MAP_TABLE_LOADER_VISIBILITY',
					visibility: false
				});

				BatchGeoStore.dispatch({
					type: 'ADMIN_MAP_TABLE_ACTIVE_FILTER',
					filter: mapsMethod
				});

				// Update the table data with the new data
				BatchGeoStore.dispatch({
					type: 'ADMIN_MAP_TABLE_ROWS_UPDATE_ALL',
					rows: data
				});
				// Uncheck all the checkboxes as you switch between the lists
				BatchGeoStore.dispatch({
					type: 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL'
				});
			})
		});
	};

	/**
	 * Creates the actual table from the given data. It calls
	 * BatchGeoAdminMapTable which handles Clusterize, templating, and everything.
	 *
	 * @param {array} mapTableData The data to build the table with. It expects
	 * the data in the format from the BatchGeo Node API
	 *
	 * @private
	 */
	BatchGeoMyMaps.prototype._initAdminMapTable = function (mapTableData) {
		new window.BatchGeoAdminMapTable({
			container: $('.admin .container')[0],
			data: mapTableData,
			clusterChanged: function () {
				// Whenever the cluster is changed we need to loop through all the
				// checkboxes in the DOM and for each checkbox we see if that checkbox
				// is marked as checked in the state. If it is, we check it in the DOM.
				$('.admin-map-table').find('[data-checkbox-id]').each(function (index, el) {
					var checkboxIds = BatchGeoStore.getState().AdminMapTableCheckboxes.checkboxes;
					var domId = $(el).data('checkbox-id');
					$(el).prop('checked', checkboxIds.indexOf(domId) > -1);
				})
			},
			template: function (row, rowIndex) {
				var isChecked = BatchGeoStore.getState().AdminMapTableCheckboxes.checkboxes.indexOf(row.map_id) > -1;
				return '' +
				  '<tr class="' + (rowIndex % 2 ? 'odd' : 'even') + '">' +
				    '<td class="admin-map-table-title-wrap">' +
				      '<div class="admin-map-table-title-wrap-inner">' +
				        '<input checked="' + isChecked + '" data-checkbox-id="' + row.map_id + '" type="checkbox" name="bulk-checkbox" class="admin-map-bulk-checkbox">' +
				        '<span class="admin-map-table-title"><a href="/map/' + row.alias + '">' + row.title + '</a>' +
				          (row.archived ? '<span class="icon toolbar-icon-archive"></span>' : '') +
				        '</span>' +
				      '</div>' +
				    '</td>' +
				    '<td class="action export-action" nowrap>' +
				      '<a class="button buttonsmall white" href="/map/pdf/' + row.alias + '" rel="nofollow">PDF</a>' +
				      '<a class="button buttonsmall white" href="/map/png/' + row.alias + '" rel="nofollow">Image</a>' +
				      '<a class="button buttonsmall white" href="/map/kml/' + row.alias + '" rel="nofollow">KML</a>' +
				    '</td>' +
				    '<td class="center updated">' + row.hit_count + '</td>' +
				    '<td class="center updated">' + row.date_updated + '</td>' +
				    '<td class="action edit-action">' +
				      '<a class="button buttonsmall white" href="/map/edit/?map_id=' + row.map_id + '&d=' + row.secret + '">Edit</a>' +
				    '</td>' +
				  '</tr>';
			}
		});

		// BatchGeoAdminMapTable will generate the search box. If there's ever no
		// rows in the table it's probably because of a search term filtering it
		// down and then the user did some action like archiving all in that filter.
		// We know that the user has maps at this point so the only other reason, so
		// far, that would cause no maps is a search term. So if no maps show this.
		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTable.displayRows;
			},
			function (newRows, oldRows) {
				return newRows.length !== oldRows.length
			},
			function (newRows, oldRows) {
				$('.admin-map-table-no-results').toggle(newRows.length < 1);
			}
		)
	};

	/**
	 * Initializes the checkboxes component that enables the checkboxes for each
	 * row as well as the listeners for each.
	 *
	 * @returns {BatchGeoAdminMapTableCheckboxes}
	 * @private
	 */
	BatchGeoMyMaps.prototype._initAdminMapTableCheckboxes = function () {
		return new window.BatchGeoAdminMapTableCheckboxes({
			container: $('.admin .container')[0],
			selectAllCheckbox: '[name=bulk-checkbox-header]',
			tableSelector: '.admin-map-table'
		});
	};

	/**
	 * Initializes the inline notification component and adds a listener for it to
	 * show whenever the notification state changes
	 *
	 * @private
	 */
	BatchGeoMyMaps.prototype._initInlineNotification = function () {
		var self = this;

		this._inlineNotification = new BatchGeoInlineNotification({
			container: '.admin-map-table-header-wrapper-outer'
		});

		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTable.notification;
			},
			function (newNotification, oldNotification) {
				return !_.isEqual(newNotification, oldNotification);
			},
			function (notification) {
				self._inlineNotification.display(notification.message, notification.type)
			}
		)
	}

	/**
	 * Initializes the action selectbox that will trigger the bulk actions when a
	 * user has checkboxes checked and picks a selectbox option.
	 *
	 * @returns {BatchGeoAdminMapTableBulkActionSelectbox}
	 * @private
	 */
	BatchGeoMyMaps.prototype._initBulkActionsSelectbox = function () {
		var self = this;

		// This will disable the selectbox when the checkbox checked count is 0
		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTableCheckboxes.checkboxes.length;
			},
			function (newCount, oldCount) {
				return newCount !== oldCount;
			},
			function (newCount, oldCount) {
				$('.admin-map-bulk-actions').prop('disabled', newCount == 0);
			}
		);

		/**
		 * A handler for all the bulk action XHRs on complete
		 * @param {object} xhr A jQuery XHR object
		 * @param {string} statusText A jQuery XHR status text string
		 * @param {string} [successVerb=saved] String to display with success message
		 */
		var onXhrComplete = function (xhr, statusText, successVerb) {
			var notification = {};
			successVerb = typeof successVerb === 'undefined' ? 'saved' : successVerb;
			if (statusText === 'success') {
				var isPlural = xhr.responseJSON.length > 1;
				notification.type = "success";
				notification.message = isPlural ? 'Maps ' + successVerb + '!' : 'Map ' + successVerb + '!';
			}
			// If it wasn't a success it must have been an error. Display the error.
			else {
				notification.type = "error";
				notification.message = '(' + xhr.status + ') ' + xhr.statusText;
			}

			BatchGeoStore.dispatch({
				type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
				notification: {
					type: notification.type,
					message: notification.message
				}
			});
		};

		return new window.BatchGeoAdminMapTableBulkActionSelectbox({
			container: '.admin-map-bulk-actions-wrapper',
			actions: {
				archive: {
					name: 'Archive Selected',
					visibility: function(maps) {
						var state = BatchGeoStore.getState().AdminMapTable;
						return ['allMaps', 'maps'].indexOf(state.filter) > -1;
					},
					complete: onXhrComplete,
					optimisticUpdate: function (data) {
						self._inlineNotification.display();

						// If we're on "allMaps" we need to update, not remove, the
						// rows since the archived list actually has ALL the maps
						if (BatchGeoStore.getState().AdminMapTable.filter === 'allMaps') {
							BatchGeoStore.dispatch({
								type: 'ADMIN_MAP_TABLE_ROWS_UPDATE',
								rows: data.map(function (map) {
									map.archived = true;
									return map;
								})
							});
						}
						// If it's the unarchived list then we need to remove the row when
						// the map is archived since it's now in a different list
						else {
							BatchGeoStore.dispatch({
								type: 'ADMIN_MAP_TABLE_ROWS_REMOVE',
								rowIds: data.map(function (map) {
									return map.map_id;
								})
							});
						}

						// This shows a notification to the user to let them know we're
						// working on saving these changes
						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
							notification: {
								type: 'info',
								message: 'Saving your changes...'
							}
						});

						// And we also need to uncheck because if not, after archiving, the
						// archived maps would still be checked
						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL'
						});
					}
				},
				unarchive: {
					name: 'Unarchive Selected',
					visibility: function(maps) {
						var state = BatchGeoStore.getState().AdminMapTable;
						return ['allMaps', 'archivedMaps'].indexOf(state.filter) > -1;
					},
					complete: onXhrComplete,
					optimisticUpdate: function (data) {
						var data = data.map(function (map) {
							map.archived = false;
							return map;
						});

						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_ROWS_UPDATE',
							rows: data
						});

						// This shows a notification to the user to let them know we're
						// working on saving these changes
						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
							notification: {
								type: 'info',
								message: 'Saving your changes...'
							}
						});

						// And we also need to uncheck because if not, after unarchiving,
						// the unarchived maps would still be checked
						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL'
						});
					}
				},
				'delete': {
					name: 'Delete Selected',
					visibility: function(maps) {
						// Only visible if all selected maps are archived
						return maps.length === maps.filter(function(map) { return map.archived; }).length;
					},
					complete: _.bind(onXhrComplete, null, _, _, 'deleted'),
					confirm: function(data) {
						var message = data.length > 1 ? 'these ' + data.length + ' maps' : 'this map';
						return confirm('Are you sure you want to delete ' + message + '?\nTHIS CANNOT BE UNDONE');
					},
					optimisticUpdate: function (data) {
						var ids = data.map(function (map) {
							return map.map_id;
						});

						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_ROWS_REMOVE',
							rowIds: ids
						});

						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL'
						});

						// This shows a notification to the user to let them know we're
						// working on deleting the maps
						BatchGeoStore.dispatch({
							type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
							notification: {
								type: 'info',
								message: 'Deleting...'
							}
						});


					}
				}
			}
		});
	};

	window.BatchGeoMyMaps = BatchGeoMyMaps;
})(window);
