require('./batchgeo-invoice-generator/batchgeo-invoice-generator.js')
require('./batchgeo-email-check/batchgeo-email-check.js')
require('./batchgeo-cookie-notice/batchgeo-cookie-notice.js')
require('./batchgeo-form/batchgeo-form.js')
require('./batchgeo-search/batchgeo-search.js')
require('./batchgeo-signup-page/batchgeo-signup-page-form.js')
require('./batchgeo-signup-page/batchgeo-signup-page.js')
require('./batchgeo-admin-addons/batchgeo-admin-addons.js')
require('./batchgeo-admin-subscription/batchgeo-admin-subscription.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-reducer.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-checkboxes-reducer.js')
require('./batchgeo-inline-notification/batchgeo-inline-notification.js')
require('./batchgeo-my-maps/batchgeo-my-maps.js')
require('./batchgeo-shared-maps/batchgeo-shared-maps.js')
require('./batchgeo-form-errors/batchgeo-form-errors.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-loader.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-checkbox.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-checkboxes.js')
require('./batchgeo-admin-map-table/batchgeo-admin-map-table-bulk-action-selectbox.js')
require('./batchgeo-admin-users/batchgeo-admin-users.js')
require('./batchgeo-admin-addons/batchgeo-admin-addons.js')
