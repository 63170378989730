BatchGeoStore.addReducer('AdminMapTableCheckboxes', function (state, action) {
	state = state || {
		checkboxes: []
	};

	switch (action.type) {
		case 'ADMIN_MAP_TABLE_CHECKBOX_CHECK':
			// We take the array of checkboxes to check and the old checkboxes and we
			// merge them together to create the new list.
			var mergedCheckboxes = state.checkboxes.concat(action.checkboxes);
			return Object.assign({}, state, {
				// Since the merge can create duplicates we filter out the dupes
				checkboxes: mergedCheckboxes.filter(function(el, index, self) {
					return index == self.indexOf(el);
				})
			});

		case 'ADMIN_MAP_TABLE_CHECKBOX_TOGGLE':
			// If it existed we'll filter out the matching one
			if (state.checkboxes.indexOf(action.checkbox) > -1) {
				return Object.assign({}, state, {
					checkboxes: state.checkboxes.filter(function(checkbox) {
						// if the current checkbox does NOT match the selected one
						// the box will be truthy and remain in the array, but if
						// the current box DOES equal the selected one it'll be false
						return checkbox !== action.checkbox;
					})
				});
			}
			// If it did not exist add it to the existing checkboxes
			else {
				return Object.assign({}, state, {
					checkboxes: [].concat(state.checkboxes, action.checkbox)
				});
			}

		case 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK':
			return Object.assign({}, state, {
				checkboxes: state.checkboxes.filter(function(el) {
					return action.checkboxes.indexOf(el) < 0;
				})
			});

		case 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL':
			return Object.assign({}, state, { checkboxes: [] });

		default:
			return state;
	}
});
