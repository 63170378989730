(function (window) {
	/**
	 * The BatchGeoSignUpPage component is a glue component to do the misc.
	 * interactivity on the /signup page. It does things like show/hide
	 * different pricing options or text and dynamically shows the expiration
	 * date based to users.
	 * 
	 * This component will generate the form but it's primary focus is NOT the
	 * actual form itself. It lets the BatchGeoSignUpPageForm component do that.
	 * This component's focus is on the page itself and modifying the plans.
	 * 
	 * @param {object} options An options hash to generate the dynamic content
	 * on the pricing page.
	 */
	var BatchGeoSignUpPage = function (options) {
		if (!options || !options.plans) {
			throw new Error('No plans were given. Please provide at least one plan.');
		}

		this.settings = $.extend(true, {
			initialPlanId: '1',
			plans: {},
			formSelector: null
		}, options)

		this._cacheElements();

		this.setCurrentPlan(this.settings.initialPlanId);

		this._bindElements();

		this._batchGeoSignUpPageForm = new BatchGeoSignUpPageForm({
			formSelector: this._elements.$form
		});
	}

	/**
	 * Cache the elements needed for the component here and attach to the proto
	 * under the _elements property. Put all selectors here in one place to make
	 * for easier updating.
	 */
	BatchGeoSignUpPage.prototype._cacheElements = function () {
		this._elements = {
			$form: $('#sign_up_form'),
			$frequencyToggle: $('input[type=radio][name=plan]'),
			$frequencyText: $('.plan-frequency'),
			$planName: $('.plan-name'),
			$cost: $('.cost-amount'),
			$cancellationDate: $('.cancellation-date'),
			$frequencyTextBlocks: $('[data-instruction]')
		}
	}

	/**
	 * Returns a cancellation date which is 1 month from the current date for
	 * the user.
	 * 
	 * @param {string|number|Date} [currentDate] A date you want to use as the
	 * current date. If you do not pass a date it defauls to Date.now().
	 * @returns {string} The current date + 1 month formatted as dd/mm/yyyy
	 */
	BatchGeoSignUpPage.prototype.getCancellationDate = function (currentDate) {
		// var currentDate = new Date(currentDate || Date.now());

		// // We add +2 to the month since it's a 0 index (0 = Jan) and we need to
		// // add 1 additional for the trial period.
		// var newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, currentDate.getDate())

		// return newDate.getMonth() + '/' + newDate.getDate() + '/' + newDate.getFullYear()


		//get current date
		var currentDate = new Date(currentDate || Date.now());
		
		//cancellation date, adding +1 to month and leaving date/year as is
		var newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());	
					
		// Adding +1 to month since getMonth() returns 0 index (Jan = 0) -> Jan = 1
		return (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();

	}

	/**
	 * Renders the content into the page. It's easiest to read the inline
	 * comments below but some examples are rendering the cancellation date or
	 * showing per year for annual plans or per month of monthly.
	 */
	BatchGeoSignUpPage.prototype.render = function () {
		var currentPlan = this.getCurrentPlan();
		var isMonthly = currentPlan.period === 'monthly';
		// Selects the current plan automatically for the user based on the
		// plan ID as if it was a default value.
		this._elements.$frequencyToggle.filter('[value=' + currentPlan.id + ']').attr('checked', true);
		// Everywhere the plan name or cost is shown update it with plan deets
		this._elements.$planName.html(currentPlan.name);
		this._elements.$cost.html(currentPlan.cost);
		// Show the cancellation date calculated 1 month from today
		this._elements.$cancellationDate.html(this.getCancellationDate());
		// Show/hide all annual or monthly related blocks based on if the
		// current plan's frequency.
		this._elements.$frequencyTextBlocks.each(function () {
			$(this).toggle($(this).data('instruction') === currentPlan.period);
		})
		this._elements.$frequencyText.html('per ' + (isMonthly ? 'month' : 'year'));
	}

	/**
	 * Returns an object like this with the plan details:
	 * 
	 * @example
	 * batchGeoSignUpPage.getcurrentPlan()
	 * 
	 * > {
	 * "id":"1",
	 * "name":"Pro Monthly",
	 * "cost":99,
	 * "period":"monthly",
	 * "level":"pro",
	 * "description":"Annual (Save 5%)"
	 * }
	 * 
	 * @returns {object} An object containing all the plan details.
	 */
	BatchGeoSignUpPage.prototype.getCurrentPlan = function () {
		return this._currentPlan;
	}

	/**
	 * Gets a plan based on the given planId
	 * 
	 * @param {string} planId The plan ID to search for
	 * 
	 * @returns {object} An object containing all the plan details.
	 * 
	 * @see {@link BatchGeoSignUpPage.getCurrentPlan} See currentPlan for an
	 * example of what the output looks lile.
	 */
	BatchGeoSignUpPage.prototype.getPlan = function (planId) {
		return _.find(this.settings.plans, function (plan) {
			// purposely == because IDs may come from inputs and not be ints
			return plan.id == planId;
		});
	}

	/**
	 * Will set the current plan based on the plan ID and then re-render itself.
	 * 
	 * @param {string} planId The plan ID to find and set the plan of
	 */
	BatchGeoSignUpPage.prototype.setCurrentPlan = function (planId) {
		this._currentPlan = this.getPlan(planId);
		this.render();
	}

	/**
	 * Binds event listeners such as setting the current plan based on the
	 * frequency toggler.
	 */
	BatchGeoSignUpPage.prototype._bindElements = function () {
		var self = this;
		this._elements.$frequencyToggle.on('change', function () {
			self.setCurrentPlan($(this).val());
		});
	}
	

	window.BatchGeoSignUpPage = BatchGeoSignUpPage;
})(window);
