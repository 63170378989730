(function (window) {
	/**
	 * Manages all the checkboxes on the AdminMapTable.
	 *
	 * @param {object} options An options hash
	 *   @param {string|jQuery} options.container The container to inject into
	 *   @param {string|jQuery} options.selectAllCheckbox The checkbox to use as
	 *   the "select all" checkbox that will trigger a select or deselect all.
	 *   @param {string|jQuery} options.tableSelector The table element of the
	 *   AdminMapTable. It will default to just looking for any "table" under the
	 *   given `container` element
	 *   @param  {string} options.idDataProp The data-* property in the DOM to
	 *   look for to retrieve the ID.
	 *
	 * @example
	 *
	 * new window.BatchGeoAdminMapTableCheckboxes({
	 *   container: $('.admin .container')[0],
	 *   selectAllCheckbox: '[name=bulk-checkbox-header]',
	 *   tableSelector: '.admin-map-table'
	 * });
	 *
	 * @constructor
	 */
	var BatchGeoAdminMapTableCheckboxes = function (options) {
		this.settings = _.merge({
			container: '',
			selectAllCheckbox: '',
			tableSelector: 'table',
			idDataProp: 'checkbox-id'
		}, options);

		this._initRowListener();
		this._initCheckboxes();
		this._initSelectAllCheckbox();
		this._initCheckboxToggleListener();
	};

	/**
	 * Given two elements, this function will select those two elements and all
	 * rows inbetween. It uses the displayData method to find the rows inbetween
	 * based on finding the first row in display data and it keeps saving the
	 * rows to the selection until it finds the last row selected's ID.
	 *
	 * @param {string|number} firstId The first ID in your selection
	 * @param {string|number} lastId  The last ID in your selection
	 * @private
	 */
	BatchGeoAdminMapTableCheckboxes.prototype._multiCheckboxSelect = function (firstId, lastId) {
		var adminMapTableRowIds = BatchGeoStore.getState().AdminMapTable.displayRows.map(function (row) {
			return row.map_id;
		});

		// We make it an array so it's easy to reverse()
		var selection = [firstId, lastId];

		// Since IDs are sequential, if first number is larger than the last number
		// then we can assume the list was reversed so we reverse this to match
		if (adminMapTableRowIds.indexOf(selection[0]) > adminMapTableRowIds.indexOf(selection[1])) {
			selection.reverse();
		}

		// Keep track of the selected rows
		var selectedRows = [];

		// Use this to flag when we should start pushing rows into our selection
		var addRows = false;

		// Using a normal for loop so we can exit early for big datasets
		for (var i = 0; i < adminMapTableRowIds.length; i++) {
			// If we found our first ID start adding the rows
			if (selection[0] == adminMapTableRowIds[i]) {
				addRows = true;
			}
			// If we have not yet found the last selection, keep adding
			if (addRows) {
				selectedRows.push(adminMapTableRowIds[i]);
			}
			// If we put the last selection into the selected rows then exit
			if (selection[1] == adminMapTableRowIds[i]) {
				break;
			}
		}

		// Trigger the checkboxes to be "checked" in the UI
		BatchGeoStore.dispatch({
			type: 'ADMIN_MAP_TABLE_CHECKBOX_CHECK',
			checkboxes: selectedRows
		});
	};

	/**
	 * Initializes the checkbox toggle listener that listens for any checkbox in
	 * the given tableSelector that has a data-* idDataProp. When it's checked we
	 * figure out if we need to toggle the checkbox or do a multi-select.
	 * @private
	 */
	BatchGeoAdminMapTableCheckboxes.prototype._initCheckboxToggleListener = function () {
		var self = this;
		var $table = $(this.settings.container).find(this.settings.tableSelector);
		var idDataProp = this.settings.idDataProp;

		$table.on('click', '[data-' + idDataProp + ']', function (event) {
			var thisCheckboxId = $(this).data(idDataProp);
			var checkboxIds = BatchGeoStore.getState().AdminMapTableCheckboxes.checkboxes;

			// If the shift key is pressed then we do multi-checkbox selections
			if (event.shiftKey) {
				// We send the last checked checkbox here along with this current one
				self._multiCheckboxSelect(checkboxIds[checkboxIds.length - 1], thisCheckboxId);
			}
			// If no shift is pressed then we handle it like a normal checkbox toggle
			else {
				BatchGeoStore.dispatch({
					type: 'ADMIN_MAP_TABLE_CHECKBOX_TOGGLE',
					checkbox: thisCheckboxId
				})
			}
		})
	};

	/**
	 * This adds a listener for the select all checkbox given in
	 * `selectAllCheckbox`. It handles either checking or unchecking everything.
	 * @private
	 */
	BatchGeoAdminMapTableCheckboxes.prototype._initSelectAllCheckbox = function () {
		var self = this;

		$(this.settings.selectAllCheckbox).on('click', function (event) {
			var adminMapTableState = BatchGeoStore.getState().AdminMapTable;
			var type = $(this).prop('checked') ? 'CHECK' : 'UNCHECK';

			BatchGeoStore.dispatch({
				type: 'ADMIN_MAP_TABLE_CHECKBOX_' + type,
				// loop through all visible rows and return the ID so all are passed
				checkboxes: adminMapTableState.displayRows.map(function (map) {
					return map.map_id;
				})
			});
		});

		// Listen for changes in the checkbox state and either check or uncheck the
		// select all checkbox if all checkboxes or checked or not.
		BatchGeoStore.addListener(
			function (state) {
				return state;
			},
			function (newState, oldState) {
				return !_.isEqual(newState.AdminMapTableCheckboxes.checkboxes, oldState.AdminMapTableCheckboxes.checkboxes);
			},
			function (newState) {
				var checkboxes = newState.AdminMapTableCheckboxes.checkboxes;
				var rows = newState.AdminMapTable.displayRows;
				// If the checkbox count is the same as the row length count then it
				// means they are all selected EXCEPT in the case that it's 0 rows and
				// 0 checkboxes.
				var allSelected = (checkboxes.length == rows.length) && checkboxes.length > 0;
				$(self.settings.selectAllCheckbox).prop('checked', allSelected);
			}
		)
	};

	/**
	 * Generates BatchGeoAdminMapTableCheckbox components for every row
	 * @private
	 */
	BatchGeoAdminMapTableCheckboxes.prototype._initCheckboxes = function () {
		BatchGeoStore.getState().AdminMapTable.allRows.map(function (checkbox) {
			new BatchGeoAdminMapTableCheckbox({
				element: '[data-' + this.settings.idDataProp + '="' + checkbox.map_id + '"]',
				id: checkbox.map_id
			});
		}, this);
	};

	/**
	 * Creates a BatchGeoStore listener to reinitialize the checkboxes if the
	 * `allRows` data changes since that means we got a new dataset
	 * @private
	 */
	BatchGeoAdminMapTableCheckboxes.prototype._initRowListener = function () {
		var self = this;
		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTable.allRows;
			},
			function (newRows, oldRows) {
				return !_.isEqual(newRows, oldRows);
			},
			function (newRows, oldRows) {
				self._initCheckboxes();
			}
		);
	};

	window.BatchGeoAdminMapTableCheckboxes = BatchGeoAdminMapTableCheckboxes;
})(window);
