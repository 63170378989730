(function(window, $) {
  var strings = BatchGeoStrings.getStringsForComponent('BatchGeoAdminAddons');
  /**
   * The BatchGeoAdminAddons object currently handles any javascript behaviors for
   * adding addons to increase usage limits.
   * @param {object} config Configurations for the object will merge and override defaults
   */
  var BatchGeoAdminAddons = function(config) {
    var options = config || {};
    var self = this;
    this.settings = _.merge({
      
    }, options);
    
    // This is a privileged method so it can read 'this', instead of being inside this.settings
    this.onReady = options.onReady ? options.onReady : onReady;

    // Perform any initialization code here. If it needs to rely on the page elements, put it inside
    // the onReady function later in this file.
    BatchGeo.timer('init');

    this.onSuccessfulAddonDelete = onSuccessfulAddonDelete;

    $(document).ready(function() {
      self.onReady();
      // Don't add default logic here, put it in the onReady function below. Each page can override
      // the onReady function when initializing a BatchGeoAdminAddons object
    });
  }
  
  /**
   * Confirms the deletion of an Addon before performing the delete action.
   * @param {number|string} addon_id Unique id of the addon
   * @param {number|string} remove_total total number of addons needed removed 
   */
   BatchGeoAdminAddons.prototype.addOnConfirm = function(update, addon_id, total) {
    if (confirm) {
      $.ajax({
        url: `/api/updateAddon/?update=${update}&addon_id=${addon_id}&total=${total}`,
        type: "post",
        success: this.onSuccessfulAddonDelete,
        error: function(textStatus, errorThrown) {
            console.log(textStatus, errorThrown);
        }
      });
      let scaledValue = total * 49.00;

      if(update !== 'delete'){
        gtag("event", "purchase", {
          transaction_id: addon_id,
          affiliation: "BatchGeo",
          value: scaledValue,
          tax: 0,
          shipping: 0,
          currency: "USD",
          coupon: "",
          items: [
          // If someone purchases more than one item, 
          // you can add those items to the items array
           {
            item_id: "SKU_12345",
            item_name: "AddOn",
            affiliation: "BatchGeo",
            coupon: "",
            discount: 0,
            index: 0,
            item_brand: "BatchGeo",
            item_category: "AddOn",
            price: scaledValue,
            quantity: total
          }]
        });
      }
		}
	}


  /**
   * The default onReady function to run when a page initializes the BatchGeoAdminAddons object * and jquery detects that the page is ready. This may be overridden on a page by page basis
   * by providing an onReady key that is a function to the BatchGeoAdminAddons options object.
   */
  function onReady() {
    BatchGeo.timer('onReady');

  }

  /**
   * Used for mocking because window.location.reload is not writable for spies
   */
  function onSuccessfulAddonDelete() {
    window.location.reload(true);
  }
  
  // Expose the constructor if we need separate instances
  window.BatchGeoAdminAddons = BatchGeoAdminAddons;
})(window, jQuery);
