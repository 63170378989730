(function (window) {
	var strings = BatchGeoStrings.getStringsForComponent('BatchGeoEmailCheck');
	/** BatchGeoEmail check is a small component designed to let a
	 * user know if we failed to deliver them an email.
	 * @constructor
	 **/
	window.BatchGeoEmailCheck = function () {
		//set this to target the map creation right now.
		var emailForm = document.querySelector('#saveMapForm');
		var LAST_EMAIL = window.BatchGeo.getCookie('LAST_EMAIL');
		var LAST_EMAIL_CHECK = window.BatchGeo.getCookie('LAST_EMAIL_CHECK');


		 if (LAST_EMAIL_CHECK != 2){
			 BatchGeo.setCookie('LAST_EMAIL_CHECK', 0, 7);
		 }

        // make sure to the a cookie for the email we are trying to verify against.
		 if (emailForm) {
			$(emailForm).on("submit", function (event) {
				event.preventDefault();
				window.BatchGeo.setCookie('LAST_EMAIL', $(this).find('[name=email]').val(), 7);
				window.BatchGeo.setCookie('LAST_EMAIL_CHECK', 0, 7);
			});
		 }

		if (LAST_EMAIL && LAST_EMAIL_CHECK != 2) {
			BatchGeoApi().emailSuccess(LAST_EMAIL, function (xhr, statusText) {
					if (statusText === 'error') {
						this.BatchGeo.customDialog(strings.get('INVALID_EMAIL_ADDRESS_ERROR'));
					}
					else if (xhr.responseJSON == 0) {
						this.BatchGeo.customDialog(strings.get('TROUBLE_SENDING_ERROR', {email: LAST_EMAIL}));
						this.BatchGeo.setCookie('LAST_EMAIL_CHECK', 2, 7);
					}
					else {
						// set another cookie so this function stops running.
						this.BatchGeo.setCookie('LAST_EMAIL_CHECK', 1, 7);
					}
			}.bind(this))

			BatchGeoApi().testEmail(LAST_EMAIL, function (xhr, statusText) {
				if (statusText === 'error') {
					this.BatchGeo.customDialog(strings.get('INVALID_EMAIL_ADDRESS_ERROR'));
				}
				else if (xhr.responseJSON == 0) {
					this.BatchGeo.customDialog(strings.get('TROUBLE_SENDING_ERROR', {email: LAST_EMAIL}));
					this.BatchGeo.setCookie('LAST_EMAIL_CHECK', 2, 7);
				}
				else {
					// set another cookie so this function stops running.
					this.BatchGeo.setCookie('LAST_EMAIL_CHECK', 1, 7);
				}
			}.bind(this))
		}
	};
})(window);
