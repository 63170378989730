(function (window) {
	/**
	 * Wires up BatchGeoStore events for a single checkbox. It makes sure that the
	 * checkbox is checked or unchecked as the checkbox's ID is added or removed
	 * from the checkbox state in AdminMapTableCheckboxes
	 *
	 * @param {object} options An options hash
	 *   @param {string} options.element A selector string (NOT jQuery) of the
	 *   checkbox element.
	 *   @param {string} options.id The ID of the checkbox.
	 *   @param {boolean} options.selected The initial selected state
	 * @constructor
	 */
	var BatchGeoAdminMapTableCheckbox = function (options) {
		var self = this;
		this.settings = $.extend({
			element: null,
			id: null,
			selected: false
		}, options);

		$(this.settings.element).prop('checked', this.settings.selected);

		BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTableCheckboxes.checkboxes;
			},
			function (newCheckboxes, oldCheckboxes) {
				return (newCheckboxes.indexOf(self.settings.id) > -1) !== (oldCheckboxes.indexOf(self.settings.id) > -1)
			},
			function (newCheckboxes, oldCheckboxes) {
				var checkbox = document.querySelector(self.settings.element);
				// The checkbox, although "selected" is not guaranteed to be in the DOM.
				// This is usually because the checkbox is removed in the DOM because of
				// clusterize and the user scrolling away from it.
				if (checkbox == null) return;
				checkbox.checked = newCheckboxes.indexOf(self.settings.id) > -1
			}
		);
	};

	window.BatchGeoAdminMapTableCheckbox = BatchGeoAdminMapTableCheckbox;
})(window);
