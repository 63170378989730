(function (window) {
	/**
	 * A component that creates inline notifications (as opposed to "toasts"). It
	 * provides the HTML, type, and timeout length all out of the box but provides
	 * many customizations such as different "types" of notifications or the
	 * template to build.
	 *
	 * @param {object} options An options hash
	 *   @param {string|jQuery} options.container The containing element that
	 *   contains the inline notification
	 *   @param {string} options.type A default type string to use for different
	 *   styling. Types are passed to the template function and, by default, used
	 *   as part of the default class name: batchgeo-inline-notification-TYPEHERE
	 *   @param {string} options.message A default message to be used when calling
	 *   the display() method
	 *   @param {number} options.timeout How long the message is displayed
	 *   @param {function} options.template A function that is called whenever
	 *   display() is called. The function will pass a settings object as it's
	 *   first parameter that will contain a message property and a type property.
	 *   NOTE: The function should return a jQuery object!
	 *
	 * @example
	 * var inlineNotification = new BatchGeoInlineNotification({
 	 *   container: '.the-container-element'
 	 * });
	 *
	 * // When you want to notify the user...
	 * inlineNotification.display("Hello World", "warning");
	 *
	 * // If you want to cancel the notification before the timeout is reached...
	 * inlineNotification.dismiss();
	 *
	 * @constructor
	 */
	var BatchGeoInlineNotification = function (options) {
		var self = this;
		this.settings = _.merge({
			container: '',
			type: 'info',
			message: '',
			timeout: 2000,
			template: function (settings) {
				var $notificationWrapper = $('<div />', {
					'class': self._generateClassName(settings.type)
				});

				$('<div />', {
					'class': 'batchgeo-inline-notification-icon',
					'html': '<span class="icon toolbar-icon-' + settings.type + '"></span>'
				}).appendTo($notificationWrapper);

				$('<div />', {
					'class': 'batchgeo-inline-notification-message',
					'html': settings.message
				}).appendTo($notificationWrapper);

				return $notificationWrapper;
			}
		}, options);

		this.render();
	};

	/**
	 * An internal initial render function. It's only run once and it builds the
	 * template based on the initial settings given by the user.
	 */
	BatchGeoInlineNotification.prototype.render = function () {
		// If it's already rendered just exit early
		if (this._$element) return;

		var settings = this.settings;
		this._$element = $(settings.template(settings)).appendTo(settings.container);

		// Hide on initial render
		this._$element.hide();
	};

	/**
	 * An internal helper function that is used in the default template function.
	 *
	 * @param {string} type Pass the given type here to have a type-specific class
	 * name for styling.
	 *
	 * @returns {string}
	 *
	 * @example
	 * this._generateClassName("warning");
	 * > 'batchgeo-inline-notification batchgeo-inline-notification-warning'
	 *
	 * @private
	 */
	BatchGeoInlineNotification.prototype._generateClassName = function (type) {
		return 'batchgeo-inline-notification batchgeo-inline-notification-' + type;
	};

	/**
	 * Displays the inline notification.
	 *
	 * @param {string} [message] The message to display to the user. By default it
	 * will use what was set in the settings.
	 * @param {string} [type] The type of notification to display. By default it
	 * will use what was in the settings, and the default setting is "info".
	 *
	 * @example
	 *
	 * var inlineNotification = new BatchGeoInlineNotification({
   *   container: '.the-container-element',
   *   message: 'Default message'
   * });
	 *
	 * // This will display 'Default message' as an info type
	 * inlineNotification.display();
	 *
	 * // This will display 'Hello world' with the warning type
	 * inlineNotification.display("Hello World", "warning");
	 *
	 */
	BatchGeoInlineNotification.prototype.display = function (message, type) {
		message = message || this.settings.message;
		type = type || this.settings.type;

		// Set this._$element to the newly created element
		var $inlineNotification = $(this.settings.template({
			message: message,
			type: type
		}));

		// Now replace it with the existing one
		this._$element = $inlineNotification.replaceAll(this._$element).show();

		clearTimeout(this._timer);

		this._timer = setTimeout(function () {
			this.dismiss();
		}.bind(this), this.settings.timeout);
	};


	/**
	 * Hides the notification
	 */
	BatchGeoInlineNotification.prototype.dismiss = function () {
		clearTimeout(this._timer);
		this._$element.hide();
	};

	/**
	 * Destroys the notification allowing you to re-render it
	 */
	BatchGeoInlineNotification.prototype.destroy = function () {
		clearTimeout(this._timer);
		this._$element.remove();
		delete this._$element;
	};

	window.BatchGeoInlineNotification = BatchGeoInlineNotification;
})(window);
