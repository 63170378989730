(function (window) {
	var strings = BatchGeoStrings.getStringsForComponent('BatchGeoAdminMapTable');
	/**
	 * BatchGeoAdminMapTableBulkActionSelectbox creates a selectbox that you
	 * define the contents of via the `actions` property in the `options` hash. It
	 * will trigger the corresponding BatchGeoApi().admin action name. See example
	 * below for more details.
	 *
	 *
	 * @param {object} options An options hash
	 *
	 *   @param {string} options.baseUrl The base of all the API urls
	 *   @param {string|jQuery} options.container A selector string or a jQuery
	 *   element object that the selectbox will get appended to.
	 *   @param {object} options.actions This is the object that wires up the
	 *   selectbox to do certain "actions" which are XHR calls to the API when the
	 *   option in the selectbox is selected. The key name (like `foo` in
	 *   `actions: {foo: bar}`) is the method that is called on
	 *   BatchGeoApi().admin() when selected.
	 *
	 *     @param {string} options.actions._actionName_.name The name that shows
	 *     up in the selectbox to the user
	 *     @param {function} options.actions._actionName_.visibility The function
	 *     that determines if the action should be available in the select element
	 *     based on the current selection. The function returns one parameter:
	 *     `maps`, an array of the currently selected map objects.
	 *     @param {function} options.actions._actionName_.confirm A function that
	 *     presents a confirm dialog to the user and waits for a positive response
	 *     before triggering the action.
	 *     @param {function} options.actions._actionName_.complete The function
	 *     that is called when the XHR is completed. It is called on a success or
	 *     a failure. The function returns two parameters: `jQueryXhrObject` and
	 *     `statusText`. You can look at jQuery's Ajax function's `complete` docs
	 *     for more info.
	 *     @param {function} options.actions._actionName_.optimisticUpdate A
	 *     function that is called immediately when the user selects it. It allows
	 *     you to give an optimistic update. For example, if the action was to
	 *     delete something you could then remove the items and then later on fail
	 *     you could restore those items to the list or try again. It returns a
	 *     `data` parameter that contains a collection of `[{map_id: 1,...}]` data
	 *     so that will let you do your own look ups or whatever else you want.
	 *
	 *
	 * @example
	 *
	 * new window.BatchGeoAdminMapTableBulkActionSelectbox({
	 *  container: '.admin-map-bulk-actions-wrapper',
	 *  actions: {
	 *    unarchive: {
	 *      name: 'Unarchive Selected',
	 *      visibility: function(maps) {
	 *      	return maps.length === maps.filter(function(map) { return map.archived; }).length;
	 *     	},
	 *      complete: function (xhr, statusText) {
	 *        var notification = {};
	 *        if (statusText == 'success') {
	 *          var isPlural = xhr.responseJSON.length > 1;
	 *          notification.type = "success";
	 *          notification.message = isPlural ? 'Maps saved!' : 'Map saved!';
	 *        }
	 *        else {
	 *          notification.type = "error";
	 *          notification.message = '(' + xhr.status + ') ' + xhr.statusText;
	 *        }
	 *
	 *        BatchGeoStore.dispatch({
	 *          type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
	 *          notification: {
	 *            type: notification.type,
	 *            message: notification.message
	 *          }
	 *        });
	 *      },
	 *      optimisticUpdate: function (data) {
	 *        var data = data.map(function (map) {
	 *          map.archived = false;
	 *          return map;
	 *        });
	 *
	 *        BatchGeoStore.dispatch({
	 *          type: 'ADMIN_MAP_TABLE_ROWS_UPDATE',
	 *          rows: data
	 *        });
	 *
	 *        BatchGeoStore.dispatch({
	 *          type: 'ADMIN_MAP_TABLE_NEW_NOTIFICATION',
	 *          notification: {
	 *            type: 'info',
	 *            message: 'Saving your changes...'
	 *          }
	 *        });
	 *
	 *        BatchGeoStore.dispatch({
	 *          type: 'ADMIN_MAP_TABLE_CHECKBOX_UNCHECK_ALL'
	 *        });
	 *      }
	 *    }
	 *  }
	 * });
	 *
	 * @constructor
	 */
	var BatchGeoAdminMapTableBulkActionSelectbox = function (options) {
		this.settings = $.extend({
			baseUrl: '/api/admin/',
			container: null,
			actions: {}
		}, options);

		this._options = {};
		var self = this;

		// Build all option els to be toggled in and out of the DOM and set some
		// defaults to the action options
		Object.keys(self.settings.actions).forEach(function (actionName) {
			self._options[actionName] = $('<option />', {
				'value': actionName,
				'text': self.settings.actions[actionName].name
			});

			// If there is no visibility or confirm set to a function than
			// always returns true otherwise default to what is given
			var action = self.settings.actions[actionName];
			action.visibility = action.visibility === undefined ? function () {return true} : action.visibility;
			action.confirm = action.confirm == undefined ? function () {return true} : action.confirm;
		});

		// Add a listener to toggle the available actions based on what checkboxes are selected
		this._bgsListener = BatchGeoStore.addListener(
			function (state) {
				return state.AdminMapTableCheckboxes.checkboxes;
			},
			function (newCheckboxes, oldCheckboxes) {
				return newCheckboxes.length !== oldCheckboxes.length;
			},
			function (newCheckboxes) {
				var selectedRows = BatchGeoStore.getState().AdminMapTable.allRows.filter(function(map){
					return newCheckboxes.indexOf(map.map_id) >= 0;
				});
				self._toggleOptions(selectedRows);
			}
		);

		this._render();
	};

	/**
	 * This renders the selectbox and all its options and adds the change event
	 * listener for when a user selects an option.
	 *
	 * @private
	 */
	BatchGeoAdminMapTableBulkActionSelectbox.prototype._render = function () {
		var self = this;
		// Create the initial select box and we disable it. We disable it because we
		// need to wait until a user selects something and then we enable it.
		var $select = $('<select />', {
			'disabled': true,
			'class': 'admin-map-bulk-actions'
		});

		// Create the initial option meant as a sort of "label" so the user knows
		// they can perform bulk actions
		$('<option />', {
			'selected': true,
			'disabled': true,
			'text': strings.get('BULK_ACTIONS')
		}).appendTo($select);

		// Change fires when a user "changes" the select box from the first option
		// that we use for a label to an option
		$select.on('change', function () {
			var actionName = $(this).val();
			var data = self._compileXhrData();
			var shouldTrigger = self.settings.actions[actionName].confirm(data);

			// Reset option selectbox back to the first option we use as a label
			// has to fire before rows are updated so select removes previous option
			// props before they are detached
			$(this).find('option').first().prop('selected', true);

			if(shouldTrigger) {
				// We need to send off the request BEFORE we call optimisticUpdate or else
				// optimisticUpdate could alter the data being sent. For example, it's
				// very common to deselect all the checkboxes in optimisticUpdate which
				// would make the xhr data be an empty array
				self._createAndSendXhrRequest(actionName);

				self.settings.actions[actionName].optimisticUpdate(data);
			}

		});

		// Once everything is setup we append to the container element
		this._$select = $select.appendTo(self.settings.container);
	};

	/**
	 * It inserts and removes options from the select element based on the currently
	 * selected map objects
	 *
	 * @param {Array} maps An array of map objects
	 * @private
	 */
	BatchGeoAdminMapTableBulkActionSelectbox.prototype._toggleOptions = function (maps) {
		var self = this;
		Object.keys(self.settings.actions).forEach(function (actionName) {
			var action = self.settings.actions[actionName];
			var $option = self._options[actionName];

			// If no visibility is given default to true, otherwise run the
			// function to set the visibility
			var visible = action.visibility(maps) && maps.length > 0;

			// If the option should be visible then append it otherwise detach
			// it if it's currently attached. We want to override the visible
			// value if there is no maps selected
			if(visible) {
				$option.appendTo(self._$select);
			}
			else if($option.parent().length && !visible) {
				$option.detach();
			}
		});
		// Disable the select if there are no available bulk actions,
		// not including the placeholder option
		self._$select.prop('disabled', self._$select.children().length < 2);
	};

	/**
	 * It takes the checkbox IDs from the AdminMapTableCheckboxes store and
	 * returns them in an array to be sent to the server and back to the client
	 *
	 * @returns {Array}
	 * @private
	 */
	BatchGeoAdminMapTableBulkActionSelectbox.prototype._compileXhrData = function () {
		return BatchGeoStore.getState().AdminMapTableCheckboxes.checkboxes.map(function (id) {
			// return an object of the map IDs in the format returned by the API
			return {"map_id": id};
		});
	};

	/**
	 * Sends a request to the BatchGeoApi with the data from the checkbox store
	 * and passes along the `complete` function from the actions hash
	 *
	 * @param {string} actionName The name of the method to call on:
	 * BatchGeoApi().admin()
	 * @private
	 */
	BatchGeoAdminMapTableBulkActionSelectbox.prototype._createAndSendXhrRequest = function (actionName) {
		BatchGeoApi().admin()[actionName](JSON.stringify(this._compileXhrData()), this.settings.actions[actionName].complete);
	};

	/**
	 * It removes the selectbox element and the store listener
	 */
	BatchGeoAdminMapTableBulkActionSelectbox.prototype.destroy = function () {
		BatchGeoStore.removeListener(this._bgsListener);
		this._$select.remove();
	};

	window.BatchGeoAdminMapTableBulkActionSelectbox = BatchGeoAdminMapTableBulkActionSelectbox;
})(window);
