(function(window, $) {
  var strings = BatchGeoStrings.getStringsForComponent('BatchGeoAdminUsers');
  /**
   * The BatchGeoAdminUsers object currently handles any javascript behaviors for the
   * admin users pages and serves as a central place for this functionality that can 
   * be effectively tested using javascript tests.
   * @param {object} config Configurations for the object will merge and override defaults
   */
  var BatchGeoAdminUsers = function(config) {
    var options = config || {};
    var self = this;
    this.settings = _.merge({
      
    }, options);
    
    // This is a privileged method so it can read 'this', instead of being inside this.settings
    this.onReady = options.onReady ? options.onReady : onReady;

    // Perform any initialization code here. If it needs to rely on the page elements, put it inside
    // the onReady function later in this file.
    BatchGeo.timer('init');

    this.formErrors = new window.BatchGeoFormErrors(options.formErrorsConfig);
    // Convenience alias
    this.validateForm = function(formKey) {
      return this.formErrors.validateForm(formKey);
    }

    this.onSuccessfulSubUserDelete = onSuccessfulSubUserDelete;

    $(document).ready(function() {
      self.onReady();
      // Don't add default logic here, put it in the onReady function below. Each page can override
      // the onReady function when initializing a BatchGeoAdminUsers object
    });
  }
  
  /**
   * Confirms the deletion of a user before performing the delete action.
   * @param {number|string} user_id Unique id of the user
   */
  BatchGeoAdminUsers.prototype.deleteConfirm = function(user_id) {
		if (confirm(strings.get('DELETE_CONFIRMATION'))) {
			$.get('/api/deleteSubUser/?user_id=' + user_id, this.onSuccessfulSubUserDelete);
		}
	}

  /**
   * Sets a sub user's admin status.
   * @param {object|string} checkbox Either a string id of a checkbox or 'this' corresponding to the actual checkbox object
   */
  BatchGeoAdminUsers.prototype.updateAdmin = function(checkbox) {
    var self = this;
    var $checkbox = $(checkbox);
    var user_id = $checkbox.val();
    var admin = $checkbox.is(':checked') ? 1 : 0;
    $.get('/api/updateSubUserAdmin/?user_id=' + user_id + '&admin=' + admin, function(result) {
      if (result != 1) {
        self.formErrors.displayModalError(strings.get('UPDATE_ERROR_MESSAGE'));
      }
    });
  }

  /**
   * The default onReady function to run when a page initializes the BatchGeoAdminUsers object
   * and jquery detects that the page is ready. This may be overridden on a page by page basis
   * by providing an onReady key that is a function to the BatchGeoAdminUsers options object.
   */
  function onReady() {
    BatchGeo.timer('onReady');

    // Do default things AdminUser needed on ready
  }

  /**
   * Used for mocking because window.location.reload is not writable for spies
   */
  function onSuccessfulSubUserDelete() {
    window.location.reload(true);
  }
  
  // Expose the constructor if we need separate instances
  window.BatchGeoAdminUsers = BatchGeoAdminUsers;
})(window, jQuery);
